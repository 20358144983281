import React from 'react';
import './App.css';
import {Hidden, Button} from '@material-ui/core';
import welcome from './images/welcomepage.png';

class Welcome extends React.Component {
  constructor(props) {
    super(props);
  }

  render() {
    return (
      <div className="App">
        <header className="App-header">Green Infrastructure</header>
          <Hidden xsDown>
              <img variant="top" src={welcome} style={{width: "58%", display: "block", marginLeft: "auto", marginRight: "auto"}}/>
              <div className="welcome" style={{textAlign: "center", fontSize: "50px"}}>Welcome!</div>
              <div className="welcome1">
                    <strong>Green Infrastructure design tool</strong> is dedicated to providing optimal vegetation designs for local communities to help mitigate traffic-related air pollution and improve their local air quality!
              </div>
              <Button variant="outlined" style={{display: "block", marginLeft: "auto", marginRight: "auto", height: "50px", width: "440px", color: "green", borderColor:"green", fontSize: "18px", marginTop: "10px"}} href="/homepage">Get your Optimal Vegetation Barrier</Button>
          </Hidden>
          <Hidden smUp>
              <img variant="top" src={welcome} style={{width: "70%", display: "block", marginLeft: "auto", marginRight: "auto"}}/>
              <div className="welcome" style={{textAlign: "center", fontSize: "30px"}}>Welcome!</div>
              <div className="welcome1" style={{fontSize:"15px"}}>
                    <strong>Green Infrastructure design tool</strong> is dedicated to providing optimal vegetation designs for local communities to help mitigate traffic-related air pollution and improve their local air quality!
              </div>
              <Button variant="outlined" style={{display: "block", marginLeft: "auto", marginRight: "auto", height: "60px", width: "220px", color: "green", borderColor:"green", fontSize: "15px", marginTop: "5px"}} href="/homepage">Get your Optimal Vegetation Barrier</Button>
          </Hidden>
      </div>
    );
  }
}

export default Welcome;