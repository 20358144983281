import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import axios from 'axios'
import {
    Button,
    Form,
    DropdownButton,
    Dropdown,
    Container,
    Row,
    Col ,
    Card,
    Table,
    Image, 
    ToggleButton,
    Collapse } from 'react-bootstrap';
import { Range, getTrackBackground, Direction } from 'react-range';
import Stepper from '@material-ui/core/Stepper';
import resultTree from './images/result_tree.png';
import item from './images/item.png';
import HelpOutlineIcon from '@material-ui/icons/HelpOutline';
import {Grid, List, ListItem, Box, Switch , Tooltip, IconButton, FormControlLabel, FormHelperText, Hidden} from '@material-ui/core';
import Step from '@material-ui/core/Step';
import StepLabel from '@material-ui/core/StepLabel';
import './App.css';
import StepContent from '@material-ui/core/StepContent';
import TextField from '@material-ui/core/TextField';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import homepage from './images/mainpage.png';
import treeWidth from './images/treeWidth.png';
import treeHeight from './images/treeHeight.png';
import higthway from './images/Highway.png';
import MoreHorizIcon from '@material-ui/icons/MoreHoriz';
import ListItemText from '@material-ui/core/ListItemText';
import ListItemAvatar from '@material-ui/core/ListItemAvatar';
import Avatar from '@material-ui/core/Avatar';
import EditIcon from '@material-ui/icons/Edit';
import CircularProgress from '@material-ui/core/CircularProgress';
const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
  },
  button: {
    marginTop: theme.spacing(1),
    marginRight: theme.spacing(1),
  },
  actionsContainer: {
    marginBottom: theme.spacing(2),
  },
  resetContainer: {
    height: "450px",
    padding: theme.spacing(3),
  },
}));

const tableFeatures = ["Rank", "Height (m)", "Width (m)", "Vegetation Species"]
const distanceOptions = ["0-10", "10-20", "20-30", "30-40", "40-50", "50-60", "60-70", "70-80", "80-90", "90-100"]
// const treeOptions = ["Coniferous", "Deciduous"]
const particleOptions = [15, 22, 30, 43, 61, 88, 126, 180, 253] 
//openweathermap API KEY
const openweathermapAPIKEY = "5a0fcc6ff448508ac87a353dd5dd9d75";
const AWSPostAPI = "https://rx5og7o9xd.execute-api.us-east-2.amazonaws.com/test/ml-models";
// for tree-width range slider
const widthSTEP = 1;
const widthMIN = 2;
const widthMAX = 12;
const heightSTEP = 1;
const distanceSTEP = 10;
const distanceMIN = 0;
const distanceMAX = 90;
let mounted = true;

export default function Main() {
  const classes = useStyles();
  const [activeStep, setActiveStep] = React.useState(0);
  const [windSpeed, setWindSpeed] = React.useState(undefined);
  const [windError, setWindError] = React.useState(false);
  const [windNotEnter, setWindNotEnter] = React.useState(false);
  const [treeWidthValues, setTreeWidthValues] = React.useState([2, 12]);
  const [treeFinalWidthToHeightValues, setTreeFinalWidthToHeightValues] = React.useState([2, 9]);
  const [treeHeightValues, setTreeHeightValues] = React.useState([2, 9]);
  const [treeSpecies1, setTreeSpecies1] = React.useState(false);
  const [treeSpecies2, setTreeSpecies2] = React.useState(false);
  const [treeSpecies3, setTreeSpecies3] = React.useState(false);
  const [treeSpecies4, setTreeSpecies4] = React.useState(false);
  const [treeSpecies5, setTreeSpecies5] = React.useState(false);
  const [treeSpecies6, setTreeSpecies6] = React.useState(false);
  const [outputSpeciesName, setOutputSpeciesName] = React.useState(undefined);
  const [particleSize, setParticleSize] = React.useState([]);
  const [chooseParticle, setChooseParticle] = React.useState(false);
  const [distance, setDistance] = React.useState([10]);
  const [isLoad, setIsLoad] =  React.useState(false);
  const [loading, setLoading] = React.useState(false);
  const [isSubmit, setIsSubmit] = React.useState(false);
  const [sortedCases, setSortedCases] = React.useState([]);
  const [user, setUserCases] = React.useState([]);
  const steps = getSteps();

  var distance1 = distance;
  var windspeed1 = windSpeed;
  var widthValues1 = treeWidthValues;
  var heightValues1 = treeHeightValues;
  var particleSize1 = particleSize;
  var lai1 = [treeSpecies1, treeSpecies2, treeSpecies3, treeSpecies4, treeSpecies5, treeSpecies6];

  function fetchWindAPI() {
    //get windspeed based on user zipcode
    let link = "https://api.openweathermap.org/data/2.5/weather?zip=" + document.getElementById("zipcode").value + ",us&APPID=" + openweathermapAPIKEY;
    fetch(link)
      .then(res => res.json())
      .then((result) => {
        console.log(result);
        if (result.message == 'invalid zip code'){
            setWindError(true);
        }else{
            setWindError(false);
            setWindNotEnter(false);
            console.log(result);
            setWindSpeed(result['wind']['speed']);
        }
      },
      (error) => {
        setWindError(true);
        console.log("loading wind API fails" + error);
      }
      )
  }
  const handleNext = () => {
    if (windSpeed === undefined){
        setWindNotEnter(true);
    }else{
        setActiveStep((prevActiveStep) => prevActiveStep + 1);
    }
  };

  const handleBack = () => {
    setWindError(false);
    setWindNotEnter(false);
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const handleReset = () => {
    setActiveStep(0);
  };

  const clickBack = () => {
    setLoading(false);
    setIsSubmit(false);
    setIsLoad(false);
    setActiveStep(0);
    setWindSpeed(undefined);
    setTreeWidthValues([2, 12]);
    setTreeFinalWidthToHeightValues([2, 9]);
    setTreeHeightValues([2, 9]);
    setTreeSpecies1(false);
    setTreeSpecies2(false);
    setTreeSpecies3(false);
    setTreeSpecies4(false);
    setTreeSpecies5(false);
    setTreeSpecies6(false);
    setOutputSpeciesName(undefined);
    setParticleSize([]);
    setDistance([10]);
  }

  const clickSubmit = () => {
    console.log("clickSubmit");
    if (windSpeed === undefined) {
      window.alert("Enter your current zipcode, wind speed required");
      return;
    } 
    if (treeSpecies1 == false && treeSpecies2 == false && treeSpecies3 == false && treeSpecies4 == false && treeSpecies5 == false && treeSpecies6 == false) {
      window.alert("Tree species required");
      return;
    }
    setLoading(true);
    setIsSubmit(true);
    setIsLoad(true);
    Post_Data(lai1, distance1, windspeed1, widthValues1, heightValues1, particleSize1);
  }

  const Post_Data = async (lai, distance, windspeed, widthValues, heightValues, particleSize) => {
    try {
        const res = await axios.post(AWSPostAPI, {
            type: 'POST',
            lai: lai,
            distance: [parseInt(distance) + 100, parseInt(distance)+ 110],
            windspeed: windspeed,
            widthValues: widthValues,
            heightValues: heightValues,
            particleSize: particleSize,
        })
        if (mounted){
            console.log("run successs");
            console.log(res);
            setUserCases(res.data);
            setSortedCases(res.data.cases.sort((a,b) => b[6]-a[6]));
            setIsLoad(false);
            setLoading(false);
        }
        return () => mounted = false;
    }catch(error){
        console.log(error)
}
}

  function getSteps() {
    return ['Wind Speed', 'Tree Width', 'Tree Height', 'Tree Species', 'Traffic Pollutant Particle Size', 'Distance from your House to Highway'];
  }
  
  function getStepContent(step) {
    switch (step) {
      case 0:
        return <GetWindSpeed/>;
      case 1:
        return <GetTreeDimensionWidth/>;
      case 2: 
      return <GetTreeDimensionHeight/>;
      case 3:
        return <GetTreeSpecies/>;
      case 4: 
        return <GetParticleSize/>;
      case 5:
        return <GetDistance/>;
      default:
        return 'Unknown step';
    }
  }
  
  function GetWindSpeed(){
      return (
          <div className="selection-item">
              <div>
                <div className="selection-name">Please enter your zip code</div>
              </div>
              <div style={{marginTop: '20px'}}>
                <Form as={Row}>
                <Col><Form.Control type="zipcode" id="zipcode"/></Col>
                  <Col><Button variant="outline-success" type="submit" onClick={fetchWindAPI}>Get</Button></Col>
                  {windError && <FormHelperText style={{color: "red", float: "left", width: "100%", marginLeft: '20px'}}>Invalid Zipcode</FormHelperText>}
                  {windNotEnter && <FormHelperText style={{color: "red", float: "left", width: "100%", marginLeft: '20px', fontSize: "15px"}}>Please enter the zipcode to get the wind speed first</FormHelperText>}
                </Form>
                <Typography style={{marginTop: 10, fontSize: '20px'}}>The wind speed in your area is: {windSpeed ? windSpeed+' m/s' : ''}</Typography>
              </div>
            </div>
      );
  }

  function GetTreeDimensionWidth(){
      const[width, setWidth] = React.useState(treeWidthValues);
      const[height, setHeight] = React.useState(treeHeightValues);
      const [final, setFinal] = React.useState(treeFinalWidthToHeightValues);
      const handleNextTree = () => {
        setTreeWidthValues(width);
        setTreeHeightValues(height);
        setTreeFinalWidthToHeightValues(final);
        setActiveStep((prevActiveStep) => prevActiveStep + 1);
      };
      return (
        <div style={{height: "500px"}}>
            <div className="selection-name">What is the tree width that you are looking for? </div>
          	<div className="dimension-range" style={{marginTop: '20px'}}>
              <Row>
              <Col md={12} className="dimension-col">
                <div className="selection-dim">Select the range for tree width</div>
                <img src={treeWidth} style={{width: "300px"}}/>
                <Typography style={{marginLeft: "133px", fontWeight: "500px", fontSize:"large"}}>Width</Typography>
                <div className="width-range">
                  <Range 
                    values={width} 
                    step={widthSTEP} 
                    min={widthMIN} 
                    max={widthMAX} 
                    onChange={values => {setWidth(values);
                      console.log("select width range " + values);
                    }}
                    onFinalChange={values => {setFinal(values.map(x => Math.round(x * 3/4)));
                        setHeight(values.map(x => Math.round(x * 3/4)));
                      console.log("select final width-to-height range " + values.map(x => Math.round(x * 3/4)));
                    }}
                    renderTrack={({ props, children }) => (
                      <div className="width-track"
                        onMouseDown={props.onMouseDown}
                        onTouchStart={props.onTouchStart}
                        style={{...props.style}}
                      >
                        <div className="width-track-background"
                          ref={props.ref}
                          style={{
                            background: getTrackBackground({
                              values: width,
                              colors: ['#ccc', '#159c16', '#ccc'],
                              min: widthMIN,
                              max: widthMAX
                            })
                          }}
                        >
                          {children}
                        </div>
                      </div>
                    )}
                    renderThumb={({ index, props, isDragged }) => (
                      <div className="width-thumb"
                        {...props}
                        style={{...props.style}}
                      >
                        <div className="width-thumb-label">
                          {width[index].toFixed(0) + 'm'}
                        </div>
                        <div
                          style={{
                            height: '16px',
                            width: '5px',
                            backgroundColor: isDragged ? '#159c16' : '#CCC'
                          }}
                        />
                      </div>
                    )}
                  />
                </div>
              </Col>
              {/* <Col md={6} className="dimension-col">
                <div className="selection-dim">Select the range for tree height</div>
                <div className="height-range">
                  <Range
                    direction={Direction.Up}
                    values={height}
                    step={heightSTEP}
                    min={final[0]}
                    max={final[1]}
                    onChange={values => {
                      setHeight(values);
                      console.log("select height range " + values);
                    }}
                    renderTrack={({ props, children }) => (
                      <div className="height-track"
                        onMouseDown={props.onMouseDown}
                        onTouchStart={props.onTouchStart}
                        style={{...props.style}}
                      >
                        <div className="height-track-background"
                          ref={props.ref}
                          style={{
                            background: getTrackBackground({
                              values: height,
                              colors: ['#ccc', '#159c16', '#ccc'],
                              min: final[0],
                              max: final[1],
                              direction: Direction.Up
                            })
                          }}
                        >
                          {children}
                        </div>
                      </div>
                    )}
                    renderThumb={({ index, props, isDragged }) => (
                      <div className="width-thumb"
                        {...props}
                        style={{...props.style}}
                      >
                        <div className="height-thumb-label">
                          {height[index].toFixed(0)}
                        </div>
                        <div
                          style={{
                            width: '16px',
                            height: '5px',
                            backgroundColor: isDragged ? '#159c16' : '#CCC'
                          }}
                        />
                      </div>
                    )}
                  />
                </div>
              </Col> */}
            </Row>
          	</div>
              <div style={{marginTop: "130px"}}>
                  <Button
                    disabled={activeStep === 0}
                    variant="contained"
                    color="primary"
                    onClick={handleBack}
                    className={classes.button}
                  >
                    Back
                  </Button>
                  <Button
                    onClick={handleNextTree}
                    className={classes.button}
                  >
                    {activeStep === steps.length - 1 ? 'Finish' : 'Next'}
                  </Button>
                </div>
          </div>
      );
  }

  function GetTreeDimensionHeight(){
    const[width, setWidth] = React.useState(treeWidthValues);
    const[height, setHeight] = React.useState(treeHeightValues);
    const [final, setFinal] = React.useState(treeFinalWidthToHeightValues);
    const handleNextTree = () => {
      setTreeWidthValues(width);
      setTreeHeightValues(height);
      setTreeFinalWidthToHeightValues(final);
      setActiveStep((prevActiveStep) => prevActiveStep + 1);
    };
    return (
      <div style={{height: "500px"}}>
            <div className="dimension-range" style={{marginTop: '20px'}}>
            <Grid container alignItems="center">
                <Grid item>
                    <div className="selection-name">What is the tree height that you are looking for? </div>
                </Grid>
                <Grid item>
                    <Tooltip title={<Typography color="inherit">We display the corresponding height range according to the width that you have chosen</Typography>}>
                        <IconButton aria-label="Question">
                            <HelpOutlineIcon />
                        </IconButton>
                    </Tooltip>
                </Grid>
            </Grid>
            <Row>
            <Col md={12} className="dimension-col">
              <div className="selection-dim">Select the range for tree height</div>
              <Grid container>
                    <Grid item xs={10}>
                        <img src={treeHeight} style={{width: "450px", marginTop: "30px"}}/>
                    </Grid>
                    <Grid item xs={1}>
                        <Typography style={{transform: 'rotate(90deg)', marginLeft: "-10px", marginTop: "150px", fontWeight: "500px", fontSize:"large"}}>Height</Typography>
                    </Grid>
                    <Grid item xs={1}>
                    <div className="height-range">
                        <Range
                    direction={Direction.Up}
                    values={height}
                    step={heightSTEP}
                    min={final[0]}
                    max={final[1]}
                    onChange={values => {
                        setHeight(values);
                        console.log("select height range " + values);
                    }}
                    renderTrack={({ props, children }) => (
                        <div className="height-track"
                        onMouseDown={props.onMouseDown}
                        onTouchStart={props.onTouchStart}
                        style={{...props.style}}
                        >
                        <div className="height-track-background"
                            ref={props.ref}
                            style={{
                            background: getTrackBackground({
                                values: height,
                                colors: ['#ccc', '#159c16', '#ccc'],
                                min: final[0],
                                max: final[1],
                                direction: Direction.Up
                            })
                            }}
                        >
                            {children}
                        </div>
                        </div>
                    )}
                    renderThumb={({ index, props, isDragged }) => (
                        <div className="width-thumb"
                        {...props}
                        style={{...props.style}}
                        >
                        <div className="height-thumb-label">
                            {height[index].toFixed(0) + 'm'}
                        </div>
                        <div
                            style={{
                            width: '16px',
                            height: '5px',
                            backgroundColor: isDragged ? '#159c16' : '#CCC'
                            }}
                        />
                        </div>
                    )}
                    />
                    </div>
                    </Grid>
                </Grid>
            </Col>
          </Row>
            </div>
            <div style={{marginTop: "130px"}}>
                <Button
                  disabled={activeStep === 0}
                  variant="contained"
                  color="primary"
                  onClick={handleBack}
                  className={classes.button}
                >
                  Back
                </Button>
                <Button
                  onClick={handleNextTree}
                  className={classes.button}
                >
                  {activeStep === steps.length - 1 ? 'Finish' : 'Next'}
                </Button>
              </div>
        </div>
    );
}

  function GetTreeSpecies(){
      const [items1, selectItems1] = React.useState(treeSpecies1);
      const [items2, selectItems2] = React.useState(treeSpecies2);
      const [items3, selectItems3] = React.useState(treeSpecies3);
      const [items4, selectItems4] = React.useState(treeSpecies4);
      const [items5, selectItems5] = React.useState(treeSpecies5);
      const [items6, selectItems6] = React.useState(treeSpecies6);
      const[error, setError] = React.useState(false);
      const selectTreeSpecies1= (e) => {
        selectItems1(!items1);
      }
      const selectTreeSpecies2= (e) => {
        selectItems2(!items2);
      }
      const selectTreeSpecies3= (e) => {
        selectItems3(!items3);
      }
      const selectTreeSpecies4= (e) => {
        selectItems4(!items4);
      }
      const selectTreeSpecies5= (e) => {
        selectItems5(!items5);
      }
      const selectTreeSpecies6= (e) => {
        selectItems6(!items6);
      }
      const handleNextTreeSpecies = () => {
        // console.log(event);
        if (items1 === false && items2 === false && items3 === false && items4 === false && items5 === false && items6 === false){
            setError(true);
        }else{
            setTreeSpecies1(items1);
            setTreeSpecies2(items2);
            setTreeSpecies3(items3);
            setTreeSpecies4(items4);
            setTreeSpecies5(items5);
            setTreeSpecies6(items6);
            var outputName = "";
            if (items1) {
              outputName = outputName + "Norway Spruce, ";
            }
            if (items2) {
              outputName = outputName + "Colorado/Blue Spruce, ";
            }
            if (items3) {
              outputName = outputName + "Silver Fir, ";
            }
            if (items4) {
              outputName = outputName + "Eastern Red Cedar, ";
            }
            if (items5) {
              outputName = outputName + "Western Hemlock, ";
            }
            if (items6) {
              outputName = outputName + "Iowa Juniper, ";
            }
            setOutputSpeciesName(outputName.slice(0, -2));
            setActiveStep((prevActiveStep) => prevActiveStep + 1);
        }
      }
      return (
        <div className="selection-item">
            <div className="selection-name">What is the tree species that you are looking for? </div>
            <div className="tree-species-selector" style={{marginTop: '20px'}}>
              <div className="tree-species-col">
                <input id="checkbox-1" type="checkbox" name="checkbox-1" checked={items1} onChange={selectTreeSpecies1}/>
                <label className="tree-checkbox-image checkbox-1" htmlFor="checkbox-1"></label>
                <p className="tree-species-name">Norway Spruce</p>
              </div>
              <div className="tree-species-col">
                <input id="checkbox-2" type="checkbox" name="checkbox-2" checked={items2} onChange={selectTreeSpecies2}/>
                <label className="tree-checkbox-image checkbox-2" htmlFor="checkbox-2"></label>
                <p className="tree-species-name">Colorado/Blue Spruce</p>
              </div>
              <div className="tree-species-col">
                <input id="checkbox-3" type="checkbox" name="checkbox-3" checked={items3} onChange={selectTreeSpecies3}/>
                <label className="tree-checkbox-image checkbox-3" htmlFor="checkbox-3"></label>
                <p className="tree-species-name">Silver Fir</p>
              </div>
            </div>
            <div className="tree-species-selector" style={{marginTop: '20px'}}>
              <div className="tree-species-col">
                <input id="checkbox-4" type="checkbox" name="checkbox-4" checked={items4} onChange={selectTreeSpecies4}/>
                <label className="tree-checkbox-image checkbox-4" htmlFor="checkbox-4"></label>
                <p className="tree-species-name">Eastern Red Cedar</p>
              </div>
              <div className="tree-species-col">
                <input id="checkbox-5" type="checkbox" name="checkbox-5" checked={items5} onChange={selectTreeSpecies5}/>
                <label className="tree-checkbox-image checkbox-5" htmlFor="checkbox-5"></label>
                <p className="tree-species-name">Western Hemlock</p>
              </div>
              <div className="tree-species-col">
                <input id="checkbox-6" type="checkbox" name="checkbox-6" checked={items6} onChange={selectTreeSpecies6}/>
                <label className="tree-checkbox-image checkbox-6" htmlFor="checkbox-6"></label>
                <p className="tree-species-name">Iowa Juniper</p>
              </div>
            </div>
        {error && <FormHelperText style={{color: "red", float: "left", width: "100%", marginLeft: '20px', fontSize: "15px"}}>Please select the tree species to enter the next step</FormHelperText>}
        <div>
                  <Button
                    disabled={activeStep === 0}
                    variant="contained"
                    color="primary"
                    onClick={handleBack}
                    className={classes.button}
                  >
                    Back
                  </Button>
                  <Button
                    onClick={handleNextTreeSpecies}
                    className={classes.button}
                  >
                    {activeStep === steps.length - 1 ? 'Finish' : 'Next'}
                  </Button>
                </div>
      </div>
      );
  }

  const particleOptions = [15, 22, 30, 43, 61, 88, 126, 180, 253] 
  
  class GetParticleSize extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
          average: chooseParticle,
          particleSizeArray: particleSize,
        }
        this.selectParticleSize = this.selectParticleSize.bind(this);
        this.changeAverage = this.changeAverage.bind(this);
    }
    componentDidMount() {
    }
  
    componentWillUnmount() {
  
    }
    selectParticleSize(event) {
        console.log(this);
        console.log(event.target.checked)
        console.log(event.target.value)
        if (event.target.type == "button"){
            var array = this.state.particleSizeArray;
            setParticleSize(array);
            setChooseParticle(this.state.average);
            setActiveStep((prevActiveStep) => prevActiveStep + 1);
        }
        if (event.target.checked){
          var array = this.state.particleSizeArray.concat(event.target.value);
          this.setState({
            particleSizeArray : array
          });
          console.log("select particleSizes" + event.target.value);
        }else{
          var array = this.state.particleSizeArray;
          const index = array.indexOf(event.target.value);
          if (index > -1) {
            array.splice(index, 1);
          }
          this.setState({
            particleSizeArray : array
          });
        }
      }
      changeAverage(event) {
        if (event.target != null){
          console.log(event.target.checked)
          this.setState({
            average : event.target.checked
          });
          if (!event.target.checked){
            this.setState({
                particleSizeArray : []
            });
          }
        }
      }

    render(){
    return (
        <div>
            <div className="selection-item" style={{float: "left", width: "100%"}}>
              <div className="selection-name" style={{float:"left", width:'100%' }}>Do you want the prediction of any particular traffic pollutant particle size?</div>
              <FormControlLabel
              style={{float: "left", width: "100%"}}
                control={
                  <Grid component="label" container alignItems="center" spacing={1} style={{marginLeft: 10}}>
                    <Grid item style={{fontWeight: 500}}>No</Grid>
                    <Grid item>
                      <Switch
                      onChange={this.changeAverage}
                      name="checkedB"
                      checked={this.state.average}
                      color="primary"
                    />
                    </Grid>
                  <Grid item style={{fontWeight: 500}}>Yes</Grid>
                  <Grid item>
                     <Tooltip title={<Typography color="inherit">If you select no, the result will be based on the average of all 9 particle sizes(15nm ~ 253nm) in our database</Typography>}>
                     <IconButton aria-label="Question">
                       <HelpOutlineIcon />
                     </IconButton>
                   </Tooltip>
                   </Grid>
                  </Grid>
                }
              />
            </div>
            {this.state.average ?
                 <div className="selection-item">
                 <Grid container alignItems="center">
                   <Grid item>
                     <div className="selection-name">Pollutant Particle Size (nm)</div>
                   </Grid>
                   <Grid item>
                     <Tooltip title={<Typography color="inherit">Please select the particle size of the traffic pollutants in your area, the prediction is given by the average of all the sizes you have selected</Typography>}>
                     <IconButton aria-label="Question">
                       <HelpOutlineIcon />
                     </IconButton>
                   </Tooltip>
                   </Grid>
                 </Grid>
                   <div style={{width: '100%'}}>
                 {particleOptions.map((opt, index) => (
                     <div key={index} id="ck-button" style={this.state.particleSizeArray.indexOf(opt.toString()) == -1 ? {backgroundColor: "white"} : {backgroundColor: "#198908"}}>
                         <label>
                             <input type="checkbox" value={opt} checked={this.state.particleSizeArray.indexOf(opt.toString()) != -1} onChange={this.selectParticleSize}/><span>{opt}</span>
                         </label>
                     </div>
                     ))}
                   </div> 
                 </div> :null}
                 <div style={{float: "left", width: '100%'}}>
                  <Button
                    disabled={activeStep === 0}
                    variant="contained"
                    color="primary"
                    onClick={handleBack}
                    className={classes.button}
                  >
                    Back
                  </Button>
                  <Button
                    onClick={this.selectParticleSize}
                    className={classes.button}
                  >
                    {activeStep === steps.length - 1 ? 'Finish' : 'Next'}
                  </Button>
                </div>
        </div>
      );
    }
  }

  function GetDistance(){
      const [items, setItems] = React.useState(distance);
      const handleNext = () => {
        setDistance(items);
        setActiveStep((prevActiveStep) => prevActiveStep + 1);
      }
      return (
        <div className="selection-item" style={{float: "left", width: "100%", height: "500px"}}>
            <div className="selection-name">How far is your house away from the Highway? </div>
        <div style={{marginTop: "40px"}}>Please drag the house to the Distance between your House to Highway</div>
      <Grid container style={{marginTop: "80px"}}>
        <Grid item xs={2}>
            <img src={higthway} style={{width: "130px", marginTop: "95px"}}/>
        </Grid>
        <Grid item xs={10}>
        <div className="selection-item">
        <div className="width-range1">
            <Range 
                values={items} 
                step={distanceSTEP} 
                min={distanceMIN} 
                max={distanceMAX} 
                onChange={values => {
            setItems(values);
            console.log("select width range " + [parseInt(values) + 100, parseInt(values) + 110]);
          }}
                renderTrack={({ props, children }) => (
                    <div className="width-track1"
                        onMouseDown={props.onMouseDown}
                        onTouchStart={props.onTouchStart}
                        style={{...props.style}}
                    >
                      <div className="width-track-background"
                          ref={props.ref}
                          style={{
                  height: "3px",
                  width: "100%",
                  borderRadius: "2px",
                              background: getTrackBackground({
                                  values: items,
                                  colors: ['#000000', '#FFFFFF'],
                                  min: distanceMIN,
                                  max: distanceMAX
                              })
                          }}
                      >
                          {children}
                      </div>
                    </div>
                  )}
                  renderThumb={({ index, props, _}) => (
                      <div className="width-thumb1"
                          {...props}
                          style={{...props.style}}
                      >
              <div className="width-thumb-label1">
                {parseInt(items) + " - " + (parseInt(items)+ 10) + "m"}
              </div>
                      </div>
                  )}
                />
            </div>
            </div>
            </Grid>
        </Grid>
        <div style={{marginTop: "80px"}}>
                  <Button
                    disabled={activeStep === 0}
                    variant="contained"
                    color="primary"
                    onClick={handleBack}
                    className={classes.button}
                  >
                    Back
                  </Button>
                  <Button
                    onClick={handleNext}
                    className={classes.button}
                  >
                    {'Finish'}
                  </Button>
              </div>
        </div>
      )
  }

  return (
    <div className={classes.root}>
        <Container className={!isSubmit && !isLoad ? '': 'hidden'}>
        <div className="selection-item">
          {/* <div className="welcome">
            Welcome! <strong>Green Infrastructure design tool</strong> is dedicated to providing optimal green infrastructure designs for your local communities, 
            using local wind speed, ideal tree dimension and species, and distance from the highway.
          </div> */}
          <Card>
            <Card.Img variant="top" src={homepage} />
          </Card>
          </div>
      <Stepper activeStep={activeStep} orientation="vertical">
        {steps.map((label, index) => (
          <Step key={label}>
            <StepLabel>{label}</StepLabel>
            <StepContent>
              <Typography component={'span'}>{getStepContent(index)}</Typography>
              <div className={classes.actionsContainer}>
                  {activeStep != 1 && activeStep != 2  && activeStep != 3 && activeStep != 4 && activeStep != 5 ? <div>
                  <Button
                    disabled={activeStep === 0}
                    variant="contained"
                    color="primary"
                    onClick={handleBack}
                    className={classes.button}
                  >
                    Back
                  </Button>
                  <Button
                    onClick={handleNext}
                    className={classes.button}
                  >
                    {activeStep === steps.length - 1 ? 'Finish' : 'Next'}
                  </Button>
                </div> : null}
              </div>
            </StepContent>
          </Step>
        ))}
      </Stepper>
      {activeStep === steps.length && (
        <Paper square elevation={0} className={classes.resetContainer}>
          <Typography>All steps completed - you&apos;ve completed the form</Typography>
          <Typography>Your selection: (Click on the edit icon to go back to the section)</Typography>
          <List className={classes.root} style={{marginTop: "30px"}}>
              <Grid container justify="center">
                  <Grid item xs={4}>
                    <ListItem>
                    <ListItemAvatar>
                    <Avatar style={{backgroundColor: "white", border: "1px black solid"}}>
                        <EditIcon onClick={value => setActiveStep(0)} style={{color: "green"}}/>
                    </Avatar>
                    </ListItemAvatar>
                    <ListItemText primary="Wind Speed" secondary={<Typography style={{color: "black", fontWeight: 2000}}>{windSpeed +' m/s'}</Typography>}/>
                </ListItem>
                  </Grid>
                  <Grid item xs={4}>
                  <ListItem>
                <ListItemAvatar>
                <Avatar style={{backgroundColor: "white", border: "1px black solid"}}>
                    <EditIcon onClick={value => setActiveStep(1)} style={{color: "green"}}/>
                </Avatar>
                </ListItemAvatar>
                <ListItemText primary="Tree Width" secondary={<Typography style={{color: "black", fontWeight: 2000}}>{treeWidthValues[0].toFixed(1) + 'm - '+treeWidthValues[1].toFixed(1) + 'm'}</Typography>}/>
            </ListItem>
                  </Grid>
                  <Grid item xs={4}>
                  <ListItem>
                <ListItemAvatar>
                <Avatar style={{backgroundColor: "white", border: "1px black solid"}}>
                    <EditIcon onClick={value => setActiveStep(2)} style={{color: "green"}}/>
                </Avatar>
                </ListItemAvatar>
                <ListItemText primary="Tree Height" secondary={<Typography style={{color: "black", fontWeight: 2000}}>{treeHeightValues[0].toFixed(1) + 'm - '+treeHeightValues[1].toFixed(1) + 'm'}</Typography>}/>
            </ListItem>
                  </Grid>
              </Grid>
              <Grid container alignItems="center" style={{marginTop: "30px"}}>
                  <Grid item xs={4}>
                        <ListItem>
                        <ListItemAvatar>
                        <Avatar style={{backgroundColor: "white", border: "1px black solid"}}>
                            <EditIcon onClick={value => setActiveStep(3)} style={{color: "green"}}/>
                        </Avatar>
                        </ListItemAvatar>
                        <ListItemText primary="Tree Species" secondary={<Typography style={{color: "black", fontWeight: 2000}}>{outputSpeciesName}</Typography>}/>
                    </ListItem>
                  </Grid>
                  <Grid item xs={4}>
                        <ListItem>
                        <ListItemAvatar>
                        <Avatar style={{backgroundColor: "white", border: "1px black solid"}}>
                            <EditIcon onClick={value => setActiveStep(4)} style={{color: "green"}}/>
                        </Avatar>
                        </ListItemAvatar>
                        <ListItemText primary="Particle Size" secondary={<Typography style={{color: "black", fontWeight: 2000}}>{particleSize.length == 0 ? 'Average of all Particle Sizes' : particleSize.map(a => a + " ")}</Typography>}/>
                    </ListItem>
                  </Grid>
                  <Grid item xs={4}>
                    <ListItem>
                    <ListItemAvatar>
                    <Avatar style={{backgroundColor: "white", border: "1px black solid"}}>
                        <EditIcon onClick={value => setActiveStep(5)} style={{color: "green"}}/>
                    </Avatar>
                    </ListItemAvatar>
                    <ListItemText primary="Distance from Highway" secondary={<Typography style={{color: "black", fontWeight: 2000}}>{parseInt(distance) + 'm - ' + (parseInt(distance) + 10) + 'm'}</Typography>}/>
                </ListItem>
                  </Grid>
              </Grid>
            </List>
          <Button onClick={handleReset} className={classes.button} style={{marginTop: "50px"}}>
            Reset
          </Button>
          <Button onClick={clickSubmit} className={classes.button} style={{marginTop: "50px"}}>
            Submit
          </Button>
        </Paper>
      )}
        </Container>
        {loading ?     
      <div style={{alignItems: "center", marginTop: 20, marginLeft: 20}}>
      <CircularProgress />
      <Typography style={{fontSize: 20, fontWeight: 500}}>We are working on finding the optimal vegetation barrier for you!</Typography>
      </div> : null}
      <div className={isSubmit && ! isLoad ? '': 'hidden'}>
          <Container className="selection">
            <div>
              <Row>
                <Col md={6}>
                  <Row className="result-row">
                    <h4>The best design option is </h4>
                    <div className="result-image">
                      <div className="result-horizontal-output">
                        {sortedCases.length !== 0 ? sortedCases[0][1] : ''}
                      </div>
                      <div className="result-image-row">
                        <img src={resultTree} />
                        <div className="result-vertical-output">
                          {sortedCases.length !== 0 ? sortedCases[0][3]+' m' : ''}
                        </div>
                      </div>
                      <div className="result-horizontal-output">
                        {sortedCases.length !== 0 ? sortedCases[0][2]+' m' : ''}
                      </div>
                    </div>
                  </Row>
                  <Row>
                    <h4 className="results-table-title">Results Table</h4>
                  </Row>
                </Col>
                <Table striped bordered hover>
                  <thead>
                    <tr>
                      {tableFeatures.map((item, index) => (
                        <th key={index}>{item}</th>
                      ))}
                      <th>Average Reduction (%)</th>
                    </tr>
                  </thead>
                  <tbody>
                    {sortedCases.map((item, index)=> (
                      <tr key={index}>
                        <td>{index+1}</td>
                        <td>{item[3]}</td>
                        <td>{item[2]}</td>
                        <td>{item[1]}</td>
                        <td>{Math.round(item[6] * 100)}</td>
                      </tr>
                    ))}
                  </tbody>
                </Table>
              </Row>
            </div>
          </Container>
        </div>
        <div className={isSubmit ? '': 'hidden'}>
          <div className="selection-item selection-submit">
          <Button variant="success" onClick={clickBack}>
            Back
          </Button>
          </div>
        </div>
    </div>
  );
}
