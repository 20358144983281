import React from 'react';
import './App.css';
import {Button, Hidden} from '@material-ui/core';
import Main from './Main';

class App extends React.Component {
  constructor(props) {
    super(props);
  }

  render() {
    return (
      <div className="App">
        <header className="App-header-Container">
          <p className="headertext">Green Infrastructure</p>
          <Hidden xsDown>
            <p className="learmore"><Button variant="outlined" style={{color:"white", borderColor:"white", marginRight: "5px", fontSize: "15px", fontWeight:"bold"}} href="/aboutus">Learn More</Button></p>
          </Hidden>
          <Hidden smUp>
            <p className="learmore"><Button variant="outlined" style={{color:"white", borderColor:"white", marginRight: "5px", fontSize: "8px", fontWeight:"bold"}} href="/aboutus">Learn More</Button></p>
          </Hidden>
        </header>
        <Main />
      </div>
    );
  }
}

export default App;
