import React from 'react';
import './App.css';
import {Button, Hidden} from '@material-ui/core';
import ArrowForwardIcon from '@material-ui/icons/ArrowForward';
import street from './images/mainpage.png';

class LearnMore extends React.Component {
  constructor(props) {
    super(props);
  }

  render() {
    return (
      <div className="App">
        <header className="App-header">Green Infrastructure</header>
         <img variant="top" src={street} style={{width: "70%", display: "block", marginLeft: "auto", marginRight: "auto"}}/>
         <Hidden xsDown>
            <div className="welcome1">
                <strong>Our Green Infrastructure tool is built in AWS cloud service. We first generate the dataset by running multiple simulations of different designs, then we train a machine learning model to do the prediction based on the dataset, and finally we upload the precomputed machine learning model to AWS. When the user provides input, the user input will be sent to the server and the result will be generated using our machine learning model.</strong>
            </div> 
            <div className="welcome1">
                <Button variant="outlined" style={{eight: "45px", width: "230px", color: "green", borderColor:"green", fontSize: "18px", marginTop: "20px"}} href="/homepage">Back to Main Page</Button>
            </div>   
          </Hidden>
          <Hidden smUp>
          <div className="welcome1" style={{fontSize: "15px"}}>
                <strong>Our Green Infrastructure tool is built in AWS cloud service. We first generate the dataset by running multiple simulations of different designs, then we train a machine learning model to do the prediction based on the dataset, and finally we upload the precomputed machine learning model to AWS. When the user provides input, the user input will be sent to the server and the result will be generated using our machine learning model.</strong>
            </div> 
            <div className="welcome1">
                <Button variant="outlined" style={{eight: "45px", width: "230px", color: "green", borderColor:"green", fontSize: "15px", marginTop: "20px"}} href="/homepage">Back to Main Page</Button>
            </div>
          </Hidden>
      </div>
    );
  }
}

export default LearnMore;